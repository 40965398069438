<template>
  <div>
    <div class="default-header cursor-pointer">
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ロゴ「Zaif INO」-->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div class="default-header__logo w-36" @click="goHome()">
        <img src="/images/zaif_logo.png" />
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ ＰＣ（横幅 1024 px 以上の時）のみ表示。右端。↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div class="hidden lg:flex justify-between items-center w-10/12">
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ ＰＣ用、                                             ↓ -->
        <!-- ↓ 検索用コンボボックス、テキストボックス、虫眼鏡ボタン ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="flex justify-between items-center">
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ ＰＣ用、 検索用カテゴリコンボボックス ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <a-select
                v-model="category_id"
                class="w-44 text-sm"
                ref="comboboxcategory"
            > <!-- @change="関数"は設定していない -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ＰＣ用、 コンボボックスの右端の▼ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                >▾
                </span>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↓ ＰＣ用「カテゴリ」選択コンボボックスの複数選択肢 ↓ -->
                <!-- --------+---------+---------+---------+---------+------ -->
                <a-select-option
                    v-for="category in this.arrayCategories"
                    :value="category.value"
                    :key="category.key"
                    class="text-sm"
                >
                    {{ category.key }}
                </a-select-option>
                <!-- --------+---------+---------+---------+---------+------ -->
                <!-- ↑ ＰＣ用「カテゴリ」選択コンボボックスの複数選択肢 ↑ -->
                <!-- --------+---------+---------+---------+---------+------ -->
            </a-select>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ ＰＣ用、 検索用カテゴリコンボボックス ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ ＰＣ用、 検索用テキストボックス ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- 日本語モード -->
            <a-input
                v-if="locale === 'ja'"
                v-model="stringForSearch"
                class="w-56"
                placeholder="NFTの検索"
                @keydown.enter="searchNFT"
            />
            <!-- 英語モード -->
            <a-input
                v-else
                v-model="stringForSearch"
                class="w-56"
                placeholder="NFT search"
                @keydown.enter="searchNFT"
            />
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ ＰＣ用、 検索用テキストボックス ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ ＰＣ用、 虫眼鏡ボタン ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <div
                @click="searchNFT"
                class="w-7 h-7 p-1 ml-1 border border-black rounded-lg
                        cursor-pointer"
            >
                <magnifyingglass></magnifyingglass>
            </div>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ ＰＣ用、 虫眼鏡ボタン ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ ＰＣ用、                                             ↑ -->
        <!-- ↑ 検索用コンボボックス、テキストボックス、虫眼鏡ボタン ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ ＰＣ用、ヘッダ右端の「ヘルプ」～「ウォレットに接続する」↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="header-content flex items-center space-x-4">
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ＰＣ用、ドロップダウンメニュー「ヘルプ」-->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="relative">
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ＰＣ用、ハイパーリンク「Zaif INOとは」 -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <a href="https://zaif-ino.com/media/help/what-is-zaif-ino/" target="_blank">
            <button class="header-button">
            {{ $t('ABOUT_ZAIFINO') }}
          </button>
          </a>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ＰＣ用、ハイパーリンク「FAQ・利用ガイド」 -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <a href="https://zaif-ino.com/media/help/" target="_blank">
            <button class="header-button">
            {{ $t('ABOUT_FAQ_GUIDE') }}
            </button>
          </a>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ＰＣ用、ハイパーリンク「お知らせ」 -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <a href="https://zaif-ino.com/media/" target="_blank">
            <button class="header-button">
              {{ $t('NOTICE') }}
            </button>
          </a>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ＰＣ用、日本語／英語切り替えスイッチ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div><LocaleSwitch></LocaleSwitch></div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ＰＣ用、ボタン「ウォレットに接続する」-->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <ConnectWallet/>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ ＰＣ用、ヘッダ右端の「ヘルプ」～「ウォレットに接続する」↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ ＰＣ（横幅 1024 px 以上の時）のみ表示。右端。↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ スマートフォン（横幅 1024 px 未満の時）のみ表示 ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div class="lg:hidden flex items-center space-x-4">
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- スマホ用、ボタン「ウォレットに接続する」-->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <ConnectWallet />
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ スマホ用、バーガーアイコンとそのクリック時の表示 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <button @click="drawerVisible = true"><BurgerIcon /></button>
        <a-drawer
          width="100%"
          placement="right"
          :visible="drawerVisible"
          @close="drawerVisible = false"
          class="wallet-drawer"
        >
          <div class="img-section border-b">
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- スマホ用、アイコン「Zaif INO」-->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <img
              src="/images/zaif_logo.png"
              class="drawer-logo"
            />
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- スマホ用、表示を閉じる「×」-->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <button @click="drawerVisible = false" class="drawer-close-button">
              <BurgerCloseIcon />
            </button>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- スマホ用、日本語／英語切り替えスイッチ -->  
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- アイコンとクローズボタンの間にセクション分けとしてボーダーを追加 -->
          <div class="border-b px-4 py-2">
            <LocaleSwitch></LocaleSwitch>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ スマホ用、                                           ↓ -->
          <!-- ↓ 検索用コンボボックス、テキストボックス、虫眼鏡ボタン ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="flex justify-start items-center pl-4 mt-2">
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ スマホ用、検索用カテゴリコンボボックス ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <a-select
                  v-model="category_id"
                  class="w-44 text-sm"
              > <!-- @change="関数"は設定していない -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- スマホ用、コンボボックスの右端の▼ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <span
                      slot="suffixIcon"
                      class="text-4xl text-black relative -top-3.5 left-2"
                  >▾
                  </span>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ スマホ用、                                ↓ -->
                  <!-- ↓「カテゴリ」選択コンボボックスの複数選択肢 ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-select-option
                      v-for="category in this.arrayCategories"
                      :value="category.value"
                      :key="category.key"
                      class="text-sm"
                  >
                      {{ category.key }}
                  </a-select-option>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ スマホ用、                                ↑ -->
                  <!-- ↑「カテゴリ」選択コンボボックスの複数選択肢 ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
              </a-select>
              <!-- ----------+---------+---------+---------+---------+------ -->
              <!-- ↑ 検索用カテゴリコンボボックス ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ スマホ用、検索用テキストボックス ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- 日本語モード -->
              <a-input
                  v-if="locale === 'ja'"
                  v-model="stringForSearch"
                  class="w-2/5"
                  placeholder="NFTの検索"
                  @keydown.enter="searchNFT"
              />
              <!-- 英語モード -->
              <a-input
                  v-else
                  v-model="stringForSearch"
                  class="w-2/5"
                  placeholder="NFT search"
                  @keydown.enter="searchNFT"
              />
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ スマホ用、検索用テキストボックス ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!--  ---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 虫眼鏡ボタン ↓ -->
              <!--  ---------+---------+---------+---------+---------+------ -->
              <div
                  @click="searchNFT"
                  class="w-7 h-7 ml-1 border border-black rounded-lg
                          cursor-pointer"
              >
                  <magnifyingglass></magnifyingglass>
              </div>
              <!--  ---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 虫眼鏡ボタン ↑ -->
              <!--  ---------+---------+---------+---------+---------+------ -->
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ スマホ用、                                           ↑ -->
          <!-- ↑ 検索用コンボボックス、テキストボックス、虫眼鏡ボタン ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- About と FAQ のボタンを追加 -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ スマホ用、「ZaifINO とは」「お知らせ」「よくある質問」↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="mt-4">
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ スマホ用、ボタン「Zaif INOとは」と詳細表示の「>」↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <a href="https://zaif-ino.com/media/help/what-is-zaif-ino/"
              target="_blank"
            >
                <button class="drawer-menu-button">
                    {{ $t('ABOUT_ZAIFINO') }}
                    <span class="drawer-menu-icon">></span>
                </button>
            </a>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ スマホ用、ボタン「Zaif INOとは」と詳細表示の「>」↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- スマホ用、ボタン「お知らせ」と詳細表示の「>」-->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <a href="https://zaif-ino.com/media/" target="_blank">
              <button class="drawer-menu-button">
                {{ $t('NOTICE') }}
                <span class="drawer-menu-icon">></span>
              </button>
            </a>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- スマホ用、ボタン「よくある質問」と詳細表示の「>」 -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <a href="https://zaif-ino.com/media/help/" target="_blank">
                <button class="drawer-menu-button">
                    {{ $t('ABOUT_FAQ_GUIDE') }}
                    <span class="drawer-menu-icon">></span>
                </button>
            </a>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ スマホ用、「ZaifINO とは」「お知らせ」「よくある質問」↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ スマホ用、アイコン群 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="px-4 py-2">
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ スマホ用、四アイコン ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <div class="social-icons">
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- スマホ用、アイコン「LINE」-->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <a href="https://line.me/R/ti/p/@841xxews?oat_content=url" target="_blank" title="LINE">
                <img src="https://api.zaif-ino.com/static/line_icon.png">
              </a>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- スマホ用、アイコン「discord」-->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <a href="https://discord.com/invite/QvbqhjRZVs" target="_blank" title="Discord">
                <img src="https://api.zaif-ino.com/static/discord_blue_icon.svg">
              </a>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- スマホ用、アイコン「メルマガ」-->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <a href="https://zaif-ino.com/media/mailmagazine/" target="_blank" title="メルマガ">
                <img src="https://api.zaif-ino.com/static/merumaga_icon.png" class="newsletter-icon">
              </a>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- スマホ用、アイコン「X(former Twitter」-->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <a href="https://x.com/zaif_ino" target="_blank" title="X">
                <img src="https://api.zaif-ino.com/static/X_black_icon.png" class="X-icon">
              </a>
            </div>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ スマホ用、四アイコン ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ スマホ用、アイコン群 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </a-drawer>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ スマホ用、バーガーアイコンとそのクリック時の表示 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ スマートフォン（横幅 1024 px 未満の時）のみ表示 ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ボタン「ウォレットに接続する」を押下時に出るポップアップ -->
      <!-- 利用規約の説明と「同意する」「同意しない」ボタン         -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <ModalRequestConnect />
    </div>
    <div class="header-spacer"></div>  <!-- 透明スペーサーを追加 -->
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import ConnectWallet from './ConnectWallet.vue';
import BurgerIcon from './icons/burger.icon.vue';
import BurgerCloseIcon from './icons/burger_close.icon.vue';
import ModalRequestConnect from './ModalRequestConnect.vue';
import { CREATE_NFT_SITE, SEND_NFT_SITE } from '@/env';
import LocaleSwitch from '@/components/common/LocaleSwitch.vue';
// import DropdownMenu from './DropdownMenu.vue';
import { mapActions, mapState } from 'vuex'                       ;
import { getCategories        } from '@/services/categories'      ;
import { PICTURE_PER_PAGE     } from '@/constants/common.constant';
import magnifyingglass          from '@/components/icons/magnifying-glass.vue';
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  components: {
    ConnectWallet,
    BurgerIcon,
    BurgerCloseIcon,
    ModalRequestConnect,
    LocaleSwitch,
    // DropdownMenu,
    magnifyingglass,
  }, // End of components: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  data() {
    return {
      drawerVisible: false,
      CREATE_NFT_SITE,
      SEND_NFT_SITE,
      // +---------+---------+---------+---------+---------+---------+---------E
      // 検索機能に使うもの。
      arrayCategories         : [], // カテゴリコンボボックスの選択肢
      objectCategoryIdNameEn  : {}, // カテゴリidと英語カテゴリ名を紐づけObj
      objectCategoryIdNameJa  : {}, // カテゴリidと日本語カテゴリ名を紐づけObj
      objectCategoryIdSequence: {}, // カテゴリidと通し番号を紐づけObj
      category_id             :  0, // カテゴリコンボボックスで選択された値
      stringForSearch         : '', // 検索用テキストボックスの内容
    }; // End of return {...}
  }, // End of data() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  computed: {
    isHomePage() {
      return this.$route.path === '/' || this.$route.path === '/about';
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapState({
        // store にセットした、言語モード（日本語／英語）。
        locale: (state) => state?.setting.locale,
        // store にセットした、ヘッダのカテゴリコンボボックスの選択値。
        object_category  : (state)=> state.extractcondition.object_category,
        // store にセットした、ヘッダの検索テキストボックスの値。
        string_for_search: (state) =>
                                      state.extractcondition.string_for_search, 
        // store にセットした、マーケットプレイスのＮＦＴ情報。
        list  : (state) => state.marketplace.marketplaceList,
    }), // End of ...mapState({...})
  }, // End of computed: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  watch: {
      // store にあるヘッダの検索情報が変化したら、表示し直す。
      object_category: function() {
          this.category_id = this.object_category.category_id;
          this.stringForSearch = '';
      },
      // store にあるヘッダの検索テキストボックスの値が変化したら、表示し直す。
      string_for_search: function() {
          this.stringForSearch = this.string_for_search
      },
      // 言語が切り替わったらコンボボックス選択肢も言語に合わせて切り替える。
      locale: async function() { await this.getFromTableCategories() },
  }, // End of watch: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  async mounted() {
      // alert(`START: mounted: DefaultHeader.vue`);
      await this.getFromTableCategories();
      // alert(`END  : mounted: DefaultHeader.vue`);
  }, // End of mounted() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  methods:{
    ...mapActions({
        // ヘッダのカテゴリコンボボックスと検索テキストボックスの値を
        // store にセット。
        setHeaderCondition: 'extractcondition/setHeaderCondition',
        // マーケットプレイスのＮＦＴ情報を store に取得。
        fetchMarketplace  : 'marketplace/fetchMarketplaceList'  ,
    }), // End of ...mapActions({...})
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ↓ テーブルcategoriesから、カテゴリ配列作成。コンボボックスで使う。↓
    // --+---------+---------+---------+---------+---------+---------+---------E
    async getFromTableCategories() {
        // alert(`START: getFromTableCategories(): DefaultHeader.vue`);
        // --------+---------+---------+---------+---------+---------+---------E
        // DBのテーブル categories からカテゴリレコードを取得し、
        // 一レコード一オブジェクトの配列arrayObjectRecordCategoriesに格納。
        // --------+---------+---------+---------+---------+---------+---------E
        const {items: arrayObjectRecordCategories} =
            await getCategories({
                // 0 はカテゴリを表す。シリーズは 2、エディションは 3。
                type     : 0, 
                // カテゴリに上位カテゴリは無いので undefined。
                hierarchy: undefined, 
                // トップページでお勧めしているカテゴリのみ取得。
                recommendation_top_as_project: 1,
                is_deleted: 0,  // 論理削除されていないカテゴリのみ取得。
            }); // End of await getCategories({...})
        // --------+---------+---------+---------+---------+---------+---------E
        // コンボボックス用のカテゴリオブジェクト配列arrayCategoriesを作成。
        // --------+---------+---------+---------+---------+---------+---------E
        // 配列の最初に、カテゴリ「全て」を表す選択肢を入れる。
        // sequence は通し番号で、カルーセル表示画面指定に使用。
        let sequence = 0;
        this.arrayCategories =
            (this.locale === 'en'  ? [{key: 'ALL'  , value: 0}]
                                   : [{key: '全て' , value: 0}]
            );
        // 同時に id から name_en が得られるオブジェクトも作成。
        this.objectCategoryIdNameEn   = {0: 'ALL' }; // 「ALL」 指定の場合。
        // 同時に id から name_ja が得られるオブジェクトも作成。
        this.objectCategoryIdNameJa   = {0: '全て'}; // 「全て」指定の場合。
        // さらに id から sequence  が得られるオブジェクトも作成。
        // 要らなくなったが、念のためコメントにしておく。
        this.objectCategoryIdSequence = {0:  0}; // 初期値
        for (const objectRecordCategory of arrayObjectRecordCategories) {
            let objectCategory = {}; // カテゴリのオブジェクト配列の一要素
            // ----+---------+---------+---------+---------+---------+---------E
            // 英語モードと日本語モード、モードに合わせたカテゴリ名を設定。
            // ----+---------+---------+---------+---------+---------+---------E
            const strCategoryName = (
                this.locale === 'en'    ? objectRecordCategory.name_en
                                        : objectRecordCategory.name_ja
            );
            // ----+---------+---------+---------+---------+---------+---------E
            objectCategory['key'  ] = strCategoryName        ;
            objectCategory['value'] = objectRecordCategory.id;
            // ----+---------+---------+---------+---------+---------+---------E
            this.arrayCategories.push(objectCategory);
            // ----+---------+---------+---------+---------+---------+---------E
            this.objectCategoryIdNameEn[objectRecordCategory.id] =
                                              objectRecordCategory.name_en;
            // ----+---------+---------+---------+---------+---------+---------E
            this.objectCategoryIdNameJa[objectRecordCategory.id] =
                                              objectRecordCategory.name_ja;
            // ----+---------+---------+---------+---------+---------+---------E
            this.objectCategoryIdSequence[objectRecordCategory.id] =
                                                                sequence++;
        } // End of for (const objectRecordCategory of ...) {...}
        // alert(`this.arrayCategories=` +
        //      `${JSON.stringify(this.arrayCategories)}: ` +
        //      `getFromTableCategories: DefaultHeader.vue`
        // );
        // alert(`this.objectCategoryIdNameEn=` +
        //      `${JSON.stringify(this.objectCategoryIdNameEn)}: ` +
        //      `getFromTableCategories: DefaultHeader.vue`
        // );
        // alert(`this.objectCategoryIdSequence=` +
        //      `${JSON.stringify(this.objectCategoryIdSequence)}: ` +
        //      `getFromTableCategories: DefaultHeader.vue`
        // );
        // alert(`END  : getFromTableCategories(): DefaultHeader.vue`);
    }, // End of async getFromTableCategories() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ↑ テーブルcategoriesから、カテゴリ配列作成。コンボボックスで使う。↑
    // --+---------+---------+---------+---------+---------+---------+---------E
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ↓ テーブルprojectsをコンボボックスとテキストボックスの値で検索 ↓
    // --+---------+---------+---------+---------+---------+---------+---------E
    async searchNFT() {
        // alert(`START: searchNFT(): DefaultHeader.vue`);
        this.loading = true;
        // --------+---------+---------+---------+---------+---------+---------E
        // カテゴリコンボボックスのカテゴリ名を変数 category_name に取得。
        const category_name = this.objectCategoryIdNameEn[this.category_id];
        // --------+---------+---------+---------+---------+---------+---------E
        // 日本語モードか否かを考慮して表示用カテゴリ名を決める。
        let category_display;
        if (this.locale === 'ja') {
            // 日本語モード
            category_display = this.objectCategoryIdNameJa[this.category_id]
        } else {
            // 英語モード
            category_display = category_name
        } // End of if (this.locale === 'ja') {...} else {...}
        // alert(`category_display=${category_display}: searchNFT: methods: DefaultHeader.vue`);
        // --------+---------+---------+---------+---------+---------+---------E
        // 検索条件を他と共有するために、Vuexでstateに登録する。
        this.setHeaderCondition({
            object_category: {
                // テーブル categories の列 id。
                category_id      : this.category_id,
                // テーブル categories の列 name_en。検索に使う。
                category_name    : category_name,
                // テーブル categories 列 name_ja or name_en。表示に使う。
                category_display : category_display,
                // 通し番号。カテゴリカルーセルの画像選択に使う。
                category_sequence: 
                            this.objectCategoryIdSequence[this.category_id],
            }, // End of object_category: {...}
            // ヘッダの検索テキストボックスの内容。
            string_for_search: this.stringForSearch,
        }); // End of this.setHeaderCondition({...})
        // --------+---------+---------+---------+---------+---------+---------E
        // 検索用の hierarchy 配列を文字列化したものstrArrayHierarchy作成。
        let strArrayHierarchy;
        if (this.category_id == 0) {
            // カテゴリコンボボックスで「全て」が選択されている。
            strArrayHierarchy = undefined
        } else {
            // カテゴリコンボボックスで「全て」以外が選択されている。
            strArrayHierarchy = JSON.stringify([
                category_name, category_name + '\\%'
            ])
        } // End of if (this.category_id == 0) {...} else {...}
        // --------+---------+---------+---------+---------+---------+---------E
        // マーケットプレイスのＮＦＴを検索。結果をstoreに保存。
        await this.fetchMarketplace({
            limit    : PICTURE_PER_PAGE, // ページ当たりのNFT表示数
            offset   : 0,
            search   : this.stringForSearch,
            // status   : this.string_status,
            hierarchy: strArrayHierarchy,
            // recommendation_top: 1,
        }); // End of await this.fetchMarketplace({...}
        this.loading = false;
        // alert(`END  : searchNFT(): DefaultHeader.vue`);
        // --------+---------+---------+---------+---------+---------+---------E
        // ドロップダウンを閉じる。ＰＣだと意味はない。
        this.closeDropdown();
        // --------+---------+---------+---------+---------+---------+---------E
        // フォーカスを検索テキストボックスから外す
        // （カテゴリコンボボックスにする）。
        this.$refs.comboboxcategory.focus();
        // alert(`this.$refs.comboboxcategory=${this.$refs.comboboxcategory}`);
        // --------+---------+---------+---------+---------+---------+---------E
        // 検索結果表示ページに遷移。
        await this.$router.push('/SearchResult');
    }, // End of async searchNFT() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ↑ テーブル projects をコンボボックスとテキストボックスの値で検索 ↑
    // --+---------+---------+---------+---------+---------+---------+---------E
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ドロップダウンを閉じる。
    // --+---------+---------+---------+---------+---------+---------+---------E
    closeDropdown() {
      this.drawerVisible = false;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // トップページに遷移。
    // --+---------+---------+---------+---------+---------+---------+---------E
    goHome() {
      // 既にルートにいる場合
      if (this.$route.path === '/') {
        window.location.reload();
      // 異なるページにいる場合、ルートに遷移
      } else {
        this.$router.push('/');
      } // End of if (this.$route.path === '/') {...} else {...}
    }, // End of goHome() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    handleNavigation(route) {
      // 同じページにいる場合
      if (this.$route.path === route) {
        this.$nextTick(() => {
          window.scrollTo({
              // ページの最初の位置にスクロール
              top: 0,
              // スムーズスクロール
              behavior: 'smooth'
          }) // End of window.scrollTo({...})
        }) // End of this.$nextTick(() => {...})
      //異なるページにいる場合
      } else {
        this.$router.push(route);
      } // End of if (this.$route.path === route) {...} else {...}
      // ドロップダウンを閉じる
      this.closeDropdown();
    }, // End of handleNavigation(route) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    FAQ(route) {
     // 既にaboutページにいる場合
      if (this.$route.path === route) {
        this.$nextTick(() => {
          window.scrollTo({
            // よくある質問の高さまでスクロールする
            top: document.body.scrollHeight* 2/5,
            // スムーズスクロール
            behavior: 'smooth'
          }) // End of window.scrollTo({...})
        }) // End of this.$nextTick(() => {...})
        // ドロップダウンを閉じる
        this.closeDropdown();

      //異なるページにいる場合
      } else {
        this.$router.push('/about').then(() => {
          // ページ遷移後に、スクロールする
          this.$nextTick(() => {
            window.scrollTo({
              // よくある質問の高さまでスクロールする
              top: document.body.scrollHeight* 2/5,
              // スムーズスクロール
              behavior: 'smooth'
            }); // End of window.scrollTo({...})
          }); // End of this.$nextTick(() => {...})
        }); // End of this.$router.push('/about').then(() => {...})
      // ドロップダウンを閉じる
      this.closeDropdown();
      } // End of if (this.$route.path === route) {...} else {...}
    } // End of FAQ(route) {...}
  } // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style lang="scss">
.default-header {
  @apply px-8 py-6 max-w-full flex items-center justify-between bg-header-bg;
  border-bottom: 1px solid #7446c7;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: white;

  &__logo {
    img {
      height: 20px;
      object-fit: contain;
    }
  }
}
.header-spacer {
  height: 50px;
  visibility: hidden;
}

/* コンボボックスの選択肢の書式を定義
       /src/assets/styles/global.scss の定義をここの !important で上書きする。
*/
.ant-select-dropdown {
  /* @apply bg-purple-bg; */
  /* border: 1px solid white; */
  background-color: white !important;
  &-menu-item {
      /* 選ばれていない選択肢 */
      background-color: white !important; /* 背景色 */
      color           : black !important; /* 文字色 */
  }
  &-menu-item-selected {
      /* 選ばれている選択肢 */
      background-color: blue  !important; /* 背景色 */
      color           : white !important; /* 文字色 */
  }
  &-menu-item-active {
      /* マウスポインタが指定している選択肢の色 */
      background-color: blue  !important; /* 背景色 */
      color           : white !important; /* 文字色 */
  }
} /* End of .ant-select-dropdown {...} */

.img-section {
  width: 100vw;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}

.drawer-logo {
  max-width: 50%;
  max-height: 40px;
}

.drawer-close-button {
  cursor: pointer;
  padding-bottom: 16px;
}

.border-b {
  border-bottom: 1px solid #ddd;
}

.drawer-menu-button {
  width: 100%;
  text-align: left;
  font-weight: bold;
  padding: 12px;
  color: black;
  border-bottom: 1px solid #ddd;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.drawer-menu-icon {
  width: 24px;
  height: 24px;
  background: #9B9DA1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000000px;
}
.header-button {
  margin-right: 20px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
}

.header-button:hover {
  color: #0056b3;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center; /* 横方向の中央揃え */
  gap: 16px;
}

.social-icons a {
  margin: 0; /* 余計なマージンをリセット */
  padding: 0; /* 余計なパディングをリセット */
}
.social-icons img {
    height: 24px;
    width: auto;
  }
  .social-icons img.X-icon {
    height: 20px;
    width: auto;
  }
  .social-icons img.newsletter-icon {
    height: 30px;
    width: auto;
  }
@media (max-width: 768px) {
.social-icons img {
    height: 30px;
    width: auto;
    margin-top: 15px;
  }
  .social-icons img.X-icon {
    height: 25px;
    width: auto;
  }
  .social-icons img.newsletter-icon {
    height: 40px;
    width: auto;
  }
}
</style>