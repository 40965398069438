<template>
  <div id="app">
    <a-spin :spinning="globalLoading">
      <main class="main">
        <DefaultHeader />
        <!-- <img src="/images/bg.png" class="img-layer" alt="" /> -->
        <div class="w-full wrapper">
          <router-view />
        </div>
        <DefaultFooter />
      </main>
    </a-spin>
    <Notify />
  </div>
</template>

<script>
import Notify from '@/components/notify';
import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultFooter from './components/DefaultFooter.vue';
import ShareListenerMixin from './mixins/share-listener.mixin';
import { mapState } from 'vuex';
export default {
  name: 'App',
  mixins: [ShareListenerMixin],
  components: {
    Notify,
    DefaultHeader,
    DefaultFooter,
  },
  async mounted() {
    await this.onInit();
  },
  watch: {
    async $route() {
      await this.onInit();
    },
  },
  computed: {
    ...mapState({
      globalLoading: (state) => state?.setting?.globalLoading || false,
    }),
  },
  methods: {},
};
</script>

<style lang="scss">
#app {
  @apply bg-no-repeat bg-contain bg-top;
  @apply relative;
  background-image: url('/images/bg.png');
  font-family: 'Poppins', sans-serif;
  @apply text-white;
  @apply text-sm;
  @apply bg-white; /* 背景色。以前は bg-purple-bg だった */
  // .img-layer {
  //   @apply absolute object-contain  pointer-events-none;
  // }
  .main {
    min-height: calc(100vh - 272px);
    width: 100%;
    @apply z-10;

    @apply mx-auto;

    .menu {
      width: 245px;
    }
    .wrapper {
      background: transparent;
    }
    .ant-spin-dot {
      font-size: 30px; /* スピンのサイズを調整 */
    }
  }
}
</style>
